<template>
  <div class="login-section-outer row">
    <div class="col-lg-8 col-md-8 col-12 px-0">
      <div
        class="d-flex flex-column min-vh-100 justify-content-center align-items-center custom-baner-logo-height-mb"
      >
        <!-- v-if="this.client_info.maa59 == null || this.client_info.maa59 == ''" -->
        <img
          class="img-fluid"
          src="/assets/images/goa-banner-image.png"
          alt="partylogo"
        />
        <!-- <img v-if="this.client_info.maa59 != null && this.client_info.maa59 != ''" class="img-fluid" :src="party_banner"
          alt="partylogo" /> -->
      </div>
    </div>
    <div class="col-lg-4 col-md-4 col-12">
      <div class="login-form-outer">
        <div class="login-form-inner">
          <div class="login-header-image text-center">
            <div v-if="onchangeuserlogo == ''">
              <img
                src="/assets/images/profile.svg"
                class="rounded-circle login-img-border"
                alt="userprofile"
                width="90"
                height="90"
              />
            </div>
            <div v-else>
              <img
                :src="this.onchangeuserlogo"
                class="rounded-circle login-img-border"
                alt="userprofile"
                width="90"
                height="90"
              />
            </div>
            <div
              class="login-header-sublabel"
              v-if="this.onchangeloginusername"
            >
              {{ this.onchangeloginusername }}
            </div>
          </div>
          <div class="login-header-label">Login</div>
          <div class="login-form-layout">
            <div class="row">
              <div class="col-12">
                <div class="custom-form-group">
                  <label for="formUsernameInput" class="form-label"
                    >Mobile No.<span class="text-danger">*</span></label
                  >
                  <input
                    type="text"
                    v-model="username"
                    class="form-control"
                    id="formUsernameInput"
                    placeholder="Enter Your Mobile No."
                    autocomplete="off"
                    maxlength="10"
                    @keypress="onlyNumberMobile"
                    tabindex="1"
                  />
                  <div class="custom-error" v-if="v$.username.$error">
                    {{ v$.username.$errors[0].$message }}
                  </div>
                </div>
              </div>
              <!-- add branchlist dropdown -->
              <div class="col-12" v-if="(branchList &&branchList.length > 1) || branchList != null">
                <div class="custom-form-group">
                  <label class="form-label">Select Branch <span class="text-danger">*</span></label>
                  <Multiselect
                      v-model="userbranchList"
                      :options="branchList"
                      :searchable="true"
                      label="label"
                      placeholder="Select"
                      class="multiselect-custom text-capitalize"
                      :canClear="true"
                      :closeOnSelect="true"
                      :object="false"
                    />
                    <div class="custom-error" v-if="v$.userbranchList.$error">
                    {{ v$.userbranchList.$errors[0].$message }}
                  </div>
                </div>
                </div>
                <!-- add branchlist dropdown -->
              <div class="col-12">
                <div class="custom-form-group">
                  <label
                    for="formPasswordInput"
                    class="form-label d-flex justify-content-between"
                  >
                    <span>Password<span class="text-danger">*</span></span
                    ><span>
                      <button
                        class="nav-link custom-forgotpassword-label-btn"
                        id="teamvoter-report-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#teamvoterreport"
                        type="button"
                        role="tab"
                        aria-controls="customer-details"
                        aria-selected="false"
                        @click="redirectforgotpasswardurl()"
                      >
                        Forgot Password?
                      </button>
                    </span></label
                  >
                  <div class="input-group">
                    <input
                      class="form-control custom-input-passward"
                      placeholder="Enter Password"
                      aria-label="Password"
                      v-model="password"
                      :type="passwordVisibility"
                      maxlength="25"
                      aria-describedby="formPasswordInput"
                      @keyup.enter="login()"
                      autocomplete="off"
                      tabindex="2"
                    />
                    <span class="input-group-text" id="formPasswordInput">
                      <button
                        @click="showPasswordType()"
                        v-if="passwordVisibility == 'password'"
                        class="btn btn-link login-password-type"
                      >
                        <i class="pi pi-eye-slash"></i>
                      </button>
                      <button
                        @click="hidePasswordType()"
                        v-if="passwordVisibility == 'text'"
                        class="btn btn-link login-password-type"
                      >
                        <i class="pi pi-eye"></i>
                      </button>
                    </span>
                  </div>
                  <div class="custom-error" v-if="errormsg">
                    {{ errormsg }}
                  </div>
                  <div
                    class="custom-error"
                    v-if="errormsg == '' && v$.password.$error"
                  >
                    {{ v$.password.$errors[0].$message }}
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="custom-form-group">
                  <button
                    class="btn login-submit-btn"
                    @click="login()"
                    type="submit"
                    tabindex="3"
                    :disabled="showloader"
                  >
                    <span v-if="!showloader">Login</span>
                    <div
                      class="spinner-border text-light custom-spinner-loader-btn"
                      role="status"
                      v-if="showloader"
                    ></div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="login-footer-outer">
          <div
            class="d-flex justify-content-between align-items-end login-footer-logo"
          >
            <img
              src="/assets/images/goa-electronics.png"
              class="img-fluid"
              alt="goa electronics"
              width="150"
            />
            <img
              src="/assets/images/logo.png"
              class="img-fluid"
              alt="goa electronics"
              width="90"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { required, helpers, maxLength, minLength } from "@vuelidate/validators";
import useValidate from "@vuelidate/core";
import ApiService from "../service/ApiService";

export default {
  data() {
    return {
      v$: useValidate(),
      username: "",
      password: "",
      passwordVisibility: "password",
      showloader: false,
      submitted: false,
      errormsg: "",
      client_info: "",
      party_logo: "",
      baseUrl: window.location.origin,
      party_banner: "",
      onchangeuserlogo: "",
      onchangeloginusername: "",
      userloginList: [],
      client_path: "",
      localstorageimgpath: "",
      userbranchList:"",
      branchList:[],
      ad23:"",
    };
  },
  watch: {
    username() {
      this.v$.$validate();
      if (this.username.length == 10) {
        this.getLoginUserByMobileNumber(this.username);
      } else {
        this.onchangeuserlogo = "";
        this.onchangeloginusername = "";
        this.errormsg = "";
      }
    },
    password() {
      this.v$.$validate();
    },
  },
  validations() {
    return {
      username: {
        required: helpers.withMessage("Please enter mobile No.", required),
        minLength: helpers.withMessage("Enter vaild mobile No.", minLength(10)),
      },
      password: {
        required: helpers.withMessage("Please enter password", required),
        maxLength: helpers.withMessage(
          "Password should be max 25 length",
          maxLength(25)
        ),
        // minLength: helpers.withMessage(
        //   "Password should be min 6 length",
        //   minLength(6)
        // ),
      },
      userbranchList: this.userloginList.ad23 == 2 && (this.branchList && this.branchList.length > 1 ||  this.branchList != null ) ? {
        required: helpers.withMessage("Please select branch", required),
      } : {}
    };
  },

  mounted() {
    this.getClientByDomain();
  },

  ApiService: null,
  created() {
    this.ApiService = new ApiService();
  },

  methods: {
    getLoginUserByMobileNumber(e) {
      this.ApiService.getLoginUserByMobileNumber({ login_id: e }).then(
        (data) => {
          if (data.status == 200) {
            this.userloginList = data.data;
            this.ad23 = this.userloginList.ad23;
            this.branchList = this.userloginList.userbranchlist;
            console.log("ad23", this.branchList);
            if (this.userloginList.ad22 && this.userloginList.ad23 == 2) {
              this.onchangeuserlogo =
                "https://storage.googleapis.com/" +
                this.localstorageimgpath +
                "/host/userprofile/" +
                this.userloginList.ad22;
            } else if (
              this.userloginList.ad22 &&
              this.userloginList.ad23 == 1
            ) {
              this.onchangeuserlogo =
                "https://storage.googleapis.com/" +
                this.localstorageimgpath +
                "/admin/userprofile/" +
                this.userloginList.ad22;
            } else if (
              this.userloginList.ad22 &&
              this.userloginList.ad23 == 3
            ) {
              this.onchangeuserlogo =
                "https://storage.googleapis.com/" +
                this.localstorageimgpath +
                "/law/userprofile/" +
                this.userloginList.ad22;
            } else {
              this.onchangeuserlogo = "";
            }
            this.onchangeloginusername = this.userloginList.ad9;
            this.errormsg = "";
          } else {
            this.errormsg = data.message;
            this.onchangeuserlogo = "";
            this.onchangeloginusername = "";
          }
        }
      );
    },
    login() {
      this.submitted = true;
      this.v$.$validate();
      let fields = {};
      fields["login_id"] = this.username;
      console.log("this.username", this.username);
      fields["login_password"] = this.password;
      console.log("this.password", this.password);
      if( this.ad23 == 2){
      if(this.branchList.length == 1){
        fields["ae1"]=this.branchList[0].value;
      }
      else {
        fields["ae1"]=this.userbranchList;
      }
      console.log("ae1", this.userbranchList);
    }
      if (!this.v$.$error) {
        this.showloader = true;
        this.ApiService.login(fields).then((data) => {
          if (data.success === true) {
            this.showloader = false;
            var currentTime = new Date().toUTCString();
            localStorage["last_login_time"] = currentTime;
            localStorage["user"] = JSON.stringify(data.data.user_data);
            localStorage["client_info"] = JSON.stringify(data.data.client_info);
            localStorage["user_reference"] = data.data.user_reference;
            localStorage["permission_fks"] = JSON.stringify(
              data.data.permission_fks
            );
            localStorage["user_login_branch"] = data.data.user_login_branch;
            window.location.href = "/dashboard";
            // var successMsg = data.message;
            // this.$toast.open({ 
            //   message: successMsg,
            //   type: "success",
            //   duration: 3000,
            //   position: "top-right",
            // });
            // setTimeout(() => {
            //   this.v$.$reset();
            // }, 0);
          } else {
            this.showloader = false;
            this.errormsg = data.message;
          }
        }).catch(($error)=> {
          console.log("error", $error)
        })
      } else {
        this.$error;
      }
    },
    showPasswordType() {
      this.passwordVisibility = "text";
    },
    hidePasswordType() {
      this.passwordVisibility = "password";
    },
    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (keyCode < 48 || keyCode > 57) {
        $event.preventDefault();
      }
    },
    onlyNumberMobile($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (keyCode < 48 || keyCode > 57) {
        $event.preventDefault();
      }

      var mobileInput = document.getElementById("formUsernameInput");
      if (
        (mobileInput.value == "" || mobileInput.length > 1) &&
        (keyCode == 48 ||
          keyCode == 49 ||
          keyCode == 50 ||
          keyCode == 51 ||
          keyCode == 52)
      ) {
        $event.preventDefault();
      }
    },
    redirectforgotpasswardurl() {
      localStorage["localusername"] = this.username;
      this.$router.push("/forgotpassword");
    },

    getClientByDomain() {
      this.ApiService.getClientByDomain().then((data) => {
        if (data.success === true) {
          this.localstorageimgpath = data.data.maa24;
          localStorage["localstoragepath"] = this.localstorageimgpath;
          // localStorage["client_info"] = JSON.stringify(
          //   items.data.client_info
          // );
          // const queryParams = new URLSearchParams(window.location.search);
          // const jsonData = queryParams.get('data');
          // if (jsonData) {
          //   const receivedData = JSON.parse(decodeURIComponent(jsonData));
          //   localStorage["user_reference"] = receivedData.user_reference;
          //   localStorage["user"] = JSON.stringify(receivedData.user_data);
          //   localStorage["permission_fks"] = receivedData.permission_fks;
          //   localStorage["client_info"] = JSON.stringify(receivedData.client_info);
          //   localStorage["LoginAsClientInClient"] = receivedData.LoginAsClientInClient;
          //   localStorage["loginUserId"] = receivedData.loginUserId;
          //   localStorage["masterbucketname"] = 'meraneta_admin';
          //   var currentTime = new Date().toUTCString();
          //   localStorage["last_login_time"] = currentTime;
          // }
          // if (localStorage.user_reference) {
          //   this.$router.push("/dashboard");
          // }
          // else if (!localStorage.client_info) {
          //   this.$router.push("/login");
          // }
          // this.client_info = JSON.parse(localStorage.client_info);
          // if (this.baseUrl == 'http://localhost:8080') {
          //   this.client_path = this.client_info.maa24;
          // } else {
          //   this.client_path = localStorage.masterbucketname;
          // }
          // this.party_logo = "https://storage.googleapis.com/" + this.client_path + "/Client/Logo/" + this.client_info.maa6;
          // // this.party_banner = "https://storage.googleapis.com/" + this.client_path + "/Client/PartyBanner/" + this.client_info.maa59;
          // // this.localstorageimgpath = this.client_info.maa24;

          // localStorage["masterbucketname"] = 'meraneta_admin';
          // if (this.baseUrl == 'http://localhost:8080') {
          //   this.client_path = 'meraneta-dev';
          // } else {
          //   this.client_path = localStorage.masterbucketname;
          // }
        }
      });
    },
  },
};
</script>
